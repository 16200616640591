<template>
  <div>
    <h1>
      {{ $t("form.title") }}
      <help>
        {{ $t("form.description").split("{icon}")[0] }}<icon id="plus" />{{
          $t("form.description").split("{icon}")[1]
        }}<icon id="brush" />{{ $t("form.description").split("{icon}")[2] }}
      </help>
    </h1>
    <assistentForm />
  </div>
</template>
<script>
export default {
  components: {
    assistentForm() {
      return import("@/components/business/form/");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
